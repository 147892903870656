import React, { useEffect, useState, useContext } from "react";
/* eslint import/no-webpack-loader-syntax: off */
import mapboxgl from "!mapbox-gl";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import * as MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import geojson2h3 from "geojson2h3";
import * as h3 from "h3-js";
import * as R from "ramda";
import OnSaleLabel from "images/forSale.png";
import lands from "helper/lands.js";

function MapSection() {
    let map;
    const [isMapReady, setIsMapReady] = useState(false);

    function waitMapStyle() {
        if (!map.isStyleLoaded()) {
            setTimeout(waitMapStyle, 200);
        } else {
            setIsMapReady(true);
        }
    }

    useEffect(() => {
        mapboxgl.accessToken =
            "pk.eyJ1IjoiYXBwdmFzIiwiYSI6ImNsMXl4bHVhMDBmemszY25zaWJscmx6ZDIifQ.9eUq0DoI9Yai6hRB6ZQpqQ";
        map = new mapboxgl.Map({
            container: "Map",
            minZoom: 1,
            style: "mapbox://styles/mapbox/outdoors-v11",
            renderWorldCopies: false,
            tileLayer: {
                continuousWorld: false,
                noWrap: true,
            },
        });
        waitMapStyle();

        var geocoder = new MapboxGeocoder({
            accessToken:
                "pk.eyJ1IjoiYXBwdmFzIiwiYSI6ImNsMXl4bHVhMDBmemszY25zaWJscmx6ZDIifQ.9eUq0DoI9Yai6hRB6ZQpqQ",
            marker: false,
        });

        map.addControl(geocoder);

        // adding coords geocoder
        var coordinatesGeocoder = (query) => {
            // Match anything which looks like
            // decimal degrees coordinate pair.
            var matches = query.match(
                /^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i
            );
            if (!matches) {
                return null;
            }

            function coordinateFeature(lng, lat) {
                return {
                    center: [lng, lat],
                    geometry: {
                        type: "Point",
                        coordinates: [lng, lat],
                    },
                    place_name: lat + "," + lng,
                    place_type: ["coordinate"],
                    properties: {},
                    type: "Feature",
                };
            }

            var coord1 = Number(matches[1]);
            var coord2 = Number(matches[2]);
            var geocodes = [];

            geocodes.push(coordinateFeature(coord2, coord1));

            return geocodes;
        };

        map.addControl(new mapboxgl.NavigationControl(), "bottom-right");

        map.addControl(
            new MapboxGeocoder({
                accessToken: mapboxgl.accessToken,
                localGeocoder: coordinatesGeocoder,
                zoom: 19,
                placeholder: "Coords: -40, 170",
                mapboxgl: mapboxgl,
            })
        );

        // geocoder init
        map.on("load", () => {
            geocoder.on("result", (ev) => {
                map.flyTo({
                    center: [
                        ev.result.geometry.coordinates[0],
                        ev.result.geometry.coordinates[1],
                    ],
                    zoom: 18,
                    speed: 1.8,
                });
            });
            map.addLayer({
                id: "mapbox-mapbox-satellite",
                source: {
                    type: "raster",
                    url: "mapbox://mapbox.satellite",
                    tileSize: 256,
                },
                type: "raster",
            });
            map.setLayoutProperty(
                "mapbox-mapbox-satellite",
                "visibility",
                "none"
            );

            var switchy = document.getElementById("js-map-view");
            if (switchy) {
                switchy.addEventListener("click", () => {
                    if (switchy.className === "on") {
                        switchy.setAttribute("class", "off");
                        map.setLayoutProperty(
                            "mapbox-mapbox-satellite",
                            "visibility",
                            "none"
                        );
                        switchy.innerHTML = "Satellite";
                    } else {
                        switchy.setAttribute("class", "on");
                        map.setLayoutProperty(
                            "mapbox-mapbox-satellite",
                            "visibility",
                            "visible"
                        );
                        switchy.innerHTML = "Streets";
                    }
                });
            }
        });

        function renderHighZoomHexes(hexagons) {
            const geojson = geojson2h3.h3SetToFeatureCollection(
                Object.keys(hexagons),
                (hex) => ({
                    value: hexagons[hex],
                })
            );

            console.log("geojson", geojson);
            const sourceId = "h3-hexes";
            const layerId = `${sourceId}-layer`;
            let source = map.getSource(sourceId);

            console.log({ source });
            if (!source) {
                map.addSource(sourceId, {
                    type: "geojson",
                    data: geojson,
                });
                map.addLayer({
                    id: layerId,
                    source: sourceId,
                    type: "fill",
                    interactive: false,
                    paint: {
                        "fill-outline-color": "#5F39BE",
                    },
                });
                source = map.getSource(sourceId);
            }

            console.log({ geojson });
            // Update the geojson data
            source.setData(geojson);

            // Update the layer paint properties, using the current config values
            map.setPaintProperty(layerId, "fill-opacity", 0.2);

            map.setPaintProperty(layerId, "fill-color", {
                property: "value",
                stops: [
                    [0, "#000000"],
                    [0.5, "#000000"],
                    [1, "#000000"],
                ],
            });
        }

        function renderHighZoomOnSaleLands(userExagons) {
            // Prepare format
            var data = Object.assign({}, userExagons);
            var newData = Object.keys(data).reduce(function (obj, key) {
                obj[data[key]] = Math.random();
                return obj;
            }, {});

            // Plot hexes
            const geojson = geojson2h3.h3SetToFeatureCollection(
                Object.keys(newData),
                (hex) => ({
                    value: userExagons[hex],
                })
            );
            const sourceId = "h3-on-sale-hexes";
            const layerId = `${sourceId}-on-sale-hexes`;
            let source = map.getSource(sourceId);

            if (!source) {
                map.addSource(sourceId, {
                    type: "geojson",
                    data: geojson,
                });
                map.addLayer({
                    id: layerId,
                    source: sourceId,
                    type: "fill",
                    interactive: false,
                    paint: {
                        "fill-outline-color": "#DE5F60",
                        "fill-color": "rgba(222, 95, 96, 0.5)",
                        "fill-opacity": 1,
                    },
                });
                source = map.getSource(sourceId);
            }

            // Update the geojson data
            source.setData(geojson);
            // Add markers
            geojson.features.forEach(function (marker) {
                // create a DOM element for the marker

                var el = document.createElement("div");
                el.className = "on-sale-marker";

                el.classList.add("hex-marker");

                //   el.style.backgroundImage = `url(${OnSaleLabel})`
                el.style.width = "59px";
                el.style.height = "33px";

                // add marker to map
                // console.log('marker.geometry.id',marker.id)
                let coordi = h3.h3ToGeo(marker.id);

                new mapboxgl.Marker(el, {
                    anchor: "center",
                })
                    .setLngLat([coordi[1], coordi[0]])
                    .addTo(map);
            });
        }

        function renderHighZoomUserMintedLands(userExagons) {
            // Prepare format
            var data = Object.assign({}, userExagons);
            var newData = Object.keys(data).reduce(function (obj, key) {
                obj[data[key]] = Math.random();
                return obj;
            }, {});

            // Plot hexes
            const geojson = geojson2h3.h3SetToFeatureCollection(
                Object.keys(newData),
                (hex) => ({
                    value: userExagons[hex],
                })
            );
            const sourceId = "h3-user-interesting-hexes";
            const layerId = `${sourceId}-user-interesting-layer`;
            let source = map.getSource(sourceId);

            if (!source) {
                map.addSource(sourceId, {
                    type: "geojson",
                    data: geojson,
                });
                map.addLayer({
                    id: layerId,
                    source: sourceId,
                    type: "fill",
                    interactive: false,
                    paint: {
                        "fill-outline-color": "#3d948d",
                        "fill-color": "rgba(4, 170, 109, 0.6)",
                        "fill-opacity": 1,
                    },
                });
                source = map.getSource(sourceId);
            }

            // Update the geojson data
            source.setData(geojson);
            // Add markers
            geojson.features.forEach(function (marker) {
                // create a DOM element for the marker

                var el = document.createElement("div");
                el.className = "sold-marker";

                el.classList.add("hex-marker");

                //   el.style.backgroundImage = `url(${OwnerLabel})`
                el.style.width = "53px";
                el.style.height = "52px";

                map.on("zoom", () => {
                    if (map.getZoom() < 17) {
                        var paras =
                            document.getElementsByClassName("sold-marker");

                        while (paras[0]) {
                            paras[0].parentNode.removeChild(paras[0]);
                        }
                    }
                });

                // add marker to map
                // console.log('marker.geometry.id',marker.id)
                let coordi = h3.h3ToGeo(marker.id);

                new mapboxgl.Marker(el, {
                    anchor: "center",
                })
                    .setLngLat([coordi[1], coordi[0]])
                    .addTo(map);
            });
        }

        function focusMap(hex_id) {
            // Hex to geo
            let hexCenterCoordinates = h3.h3ToGeo(hex_id);
            // Move map focus
            map.flyTo({
                center: [hexCenterCoordinates[1], hexCenterCoordinates[0]],
                zoom: 17,
                speed: 1.8,
            });
            // Plot graphic point into map
            let singleHexGeojson = geojson2h3.h3ToFeature(hex_id);

            const selected_sourceId = "h3-hexes_selected";
            const selected_layerId = `${selected_sourceId}-layer`;
            let selected_source = map.getSource(selected_sourceId);
            if (!selected_source) {
                map.addSource(selected_sourceId, {
                    type: "geojson",
                    data: singleHexGeojson,
                });
                map.addLayer({
                    id: selected_layerId,
                    source: selected_sourceId,
                    type: "fill",
                    interactive: false,
                    paint: {
                        "fill-outline-color": "#4A90E2",
                        "fill-color": "rgba(74,144,226,0.20)",
                        "fill-opacity": 1,
                    },
                });
                selected_source = map.getSource(selected_sourceId);
            }
            // Update the h3Geo data
            selected_source.setData(singleHexGeojson);
            map.setLayoutProperty(selected_layerId, "visibility", "visible");
        }

        function hexagons() {
            var center = map.getCenter();
            console.log({center})
            const centerHex = h3.geoToH3(center["lat"], center["lng"], 12);
            const kRing = h3.kRing(centerHex, 25);
            var data = Object.assign({}, kRing);
            var newData = Object.keys(data).reduce(function (obj, key) {
                obj[data[key]] = Math.random();
                return obj;
            }, {});
            return newData;
        }
        function addFocusToHexId(hexId) {
            let hexCenterCoordinates = h3.h3ToGeo(hexId);
            const selectedSourceId = "h3-hexes_selected";

            map.flyTo({
                center: [hexCenterCoordinates[1], hexCenterCoordinates[0]],
                zoom: 18,
                speed: 2.2,
            });
        }

        setTimeout(() => {
            map.flyTo({
                center: [-72.9066536, 40.7907003],
                zoom: 17,
                speed: 3,
            });
            setTimeout(() => {
                // console.log({ fff: hexagons() });
                renderHighZoomHexes(hexagons());
                let landList = lands.list();
                // renderHighZoomOnSaleLands(["8c1fb467450a9ff", "8c1fb467452b1ff", "8c1fb4674571dff", "8c1fb467454e7ff", "8c1fb4674554dff", "8c1fb46745541ff", "8c1fb46745543ff", "8c1fb46745509ff", "8c1fb46745547ff", "8c1fb46745545ff", "8c1fb46745093ff"]);
                // renderHighZoomUserMintedLands(["8c1fb4674570dff", "8c1fb46744057ff", "8c1fb46744311ff", "8c1fb46744365ff"]);

                renderHighZoomOnSaleLands(landList.onSale);
                renderHighZoomUserMintedLands(landList.sold);
                // focusMap("8c2a157945a51ff");
                let land = lands.find("8c2a157945a51ff");

                window.sidebarData({
                    hex: "8c2a157945a51ff",
                    lat: 40.7907003,
                    lng: -72.9066536,
                    data: land[0],
                });
            }, 1000);
        }, 2000);

        //   useEffect(() => {
        //     if(isMapReady == true){

        //     }
        //   }, [isMapReady])

        map.on("click", (e) => {
            console.log({ e });
            const clicked_hex_id = h3.geoToH3(
                e.lngLat["lat"],
                e.lngLat["lng"],
                12
            );

            console.log({ clicked_hex_id });

            focusMap(clicked_hex_id);
            let land = lands.find(clicked_hex_id);

            window.sidebarData({
                hex: clicked_hex_id,
                lat: e.lngLat["lat"],
                lng: e.lngLat["lng"],
                data: land[0],
            });
        });
    }, []);

    return (
        <div className="w-full md:w-4/5 bg-gray-300">
            <div id={"Map"} className="Map h-[35rem] md:h-full"></div>
        </div>
    );
}

export default MapSection;
