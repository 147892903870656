import * as landData from 'data/lands.json'

class lands{

    constructor() {
        this.landData = landData.default
    }

    list() {
        let landObj = {
            onSale : [],
            sold: []
        };
        for (let index = 0; index < this.landData.length; index++) {
            const element = this.landData[index];
            if(element.type == 2){
                landObj.onSale.push(element.hexId)
            }
            
            if(element.type == 3){
                landObj.sold.push(element.hexId)
            } 
        }
        return landObj
    }

    find(hex_id) {
        return this.landData.filter(function(land){return land.hexId === hex_id});
    }
}

export default new lands;