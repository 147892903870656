import React, { useEffect, useState } from "react";
import { GoLocation, GoPlus, GoPin } from "react-icons/go";
import item1Image from "images/home.png";
import arButtonImage from "images/ar-button.png";
import logoIcon from "images/logo-icon.png";

import avatar1 from "images/avatars/1.png";
import avatar2 from "images/avatars/2.png";
import avatar3 from "images/avatars/3.png";
import { Dialog } from "@headlessui/react";
import { RiShareForwardFill } from "react-icons/ri";
import { FaCheckCircle } from "react-icons/fa";

function Sidebar() {
    const [selectedLand, setSelectedLand] = useState([]);
    let [isOpen, setIsOpen] = useState(false);

    window.sidebarData = (data) => {
        setSelectedLand(data);
    };

    useEffect(() => {
        console.log("selected land changed");
        console.log(selectedLand);
    }, [selectedLand]);

    return (
        <>
            <div
                className={
                    "fixed w-full h-screen z-50 top-0 left-0 bg-black bg-opacity-30 justify-center items-center " +
                    (isOpen ? "flex" : "hidden")
                }
            >
                <div className="w-80 h-44 bg-white rounded-3xl relative flex justify-center items-center">
                    <button
                        onClick={() => {
                            setIsOpen(false);
                        }}
                        className="absolute top-3 right-3 font-black"
                    >
                        X
                    </button>
                    <span>This only demo feature!</span>
                </div>
            </div>
            <div className="w-full md:w-1/5 flex-auto bg-white p-6 flex flex-col space-y-5 overflow-scroll min-h-[50%] md:min-h-0">
                {/* <label class="relative bg-transparent block">
            <svg class="pointer-events-none w-6 h-6 absolute top-1/2 transform -translate-y-1/2 left-3 fill-[#7696D4]" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">
                <path d="M15.853 16.56c-1.683 1.517-3.911 2.44-6.353 2.44-5.243 0-9.5-4.257-9.5-9.5s4.257-9.5 9.5-9.5 9.5 4.257 9.5 9.5c0 2.442-.923 4.67-2.44 6.353l7.44 7.44-.707.707-7.44-7.44zm-6.353-15.56c4.691 0 8.5 3.809 8.5 8.5s-3.809 8.5-8.5 8.5-8.5-3.809-8.5-8.5 3.809-8.5 8.5-8.5z"/>
            </svg>
            <input type="email" name="email" id="email" placeholder="Search" class="form-input rounded-lg py-[0.62rem] px-4 text-md bg-transparent border border-[#7696D4] placeholder-[#7696D4] text-gray-500 appearance-none w-full block pl-12 focus:outline-none"/>
        </label> */}

                {!selectedLand?.lat && <span className="mx-auto">92, -18</span>}
                <div className="flex flex-col space-y-4">
                    {selectedLand?.lat && (
                        <div className="gap-y-4 flex flex-col">
                            <div className="flex flex-row justify-between items-center">
                                <div className="flex flex-col gap-y-1">
                                    <span className="font-bold">92, -18</span>
                                    <span className="text-sm">SALE ON</span>
                                </div>
                                <div className="ml-auto p-1.5 bg-[#C5D4E7] rounded-full flex items-center">
                                    <RiShareForwardFill
                                        color="white"
                                        size={20}
                                    />
                                </div>
                            </div>

                            {selectedLand?.data && (
                                <>
                                    <div className="bg-[#C8D6E8] bg-opacity-20 py-10 px-5 rounded-xl relative border border-[#C8D6E8]">
                                        <button className="absolute top-3">
                                            <img
                                                className="h-8"
                                                src={arButtonImage}
                                            />
                                        </button>
                                        <img
                                            className="mx-auto w-full"
                                            src={item1Image}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                    {selectedLand?.lat && (
                        <>
                            {selectedLand.data?.type && (
                                <div className="flex flex-row justify-between items-center px-3">
                                    <div className="flex flex-col">
                                        <span className="text-xs">PRICE</span>
                                        <span className="font-semibold">
                                            0,1 METAXA
                                        </span>
                                    </div>

                                    <div>
                                        <FaCheckCircle size={20} />
                                    </div>
                                </div>
                            )}
                            {selectedLand.data?.type == 2 && (
                                <>
                                    <div
                                        onClick={() => {
                                            setIsOpen(true);
                                        }}
                                        className="font-bold text-[0.93rem] py-3 px-6 mx-7 cursor-pointer text-white bg-gradient-to-r from-[#FF0099] to-[#8000E4] rounded-full flex items-center justify-center"
                                    >
                                        <span>BUY NOW</span>
                                    </div>
                                </>
                            )}
                            {selectedLand.data?.type == 3 && (
                                <>
                                    <div
                                        onClick={() => {
                                            setIsOpen(true);
                                        }}
                                        className="font-bold text-[0.93rem] py-3 px-6 mx-7 cursor-pointer text-white bg-[#2D3D51] rounded-full flex items-center justify-center"
                                    >
                                        <span>SOLD</span>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

export default Sidebar;
