import React from "react";
import logoWhite from "images/logo-white.png";
import logoIcon from "images/logo-icon.png";
import { useState } from "react";

function Header() {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <div className="w-full py-3 bg-white text-[#2D3D51] drop-shadow-sm border-b-[0.5px] border-gray-300">
                <div className="w-full flex flex-col md:flex-row justify-between items-center container mx-auto">
                    <a
                        href="https://metaxaofficial.com"
                        className="flex flex-row space-x-3 items-center"
                    >
                        {/* <div className="w-7 h-7 bg-[#2D3D51] rounded-full"></div>
                        <span className="text-lg font-bold">MetaNew</span> */}
                        <img src="/logo.png" className="h-8" />
                    </a>

                    <div className="mx-auto md:mr-0 md:ml-auto mt-5 md:mt-0 flex flex-row gap-x-3">
                        <a
                            href="/#"
                            className="flex flex-col gap-y-1 bg-white p-1 rounded-md"
                        >
                            <span className="text-xs">Your Balance</span>
                            <span className="text-xs font-semibold">
                                0,0 METAXA
                            </span>
                        </a>
                        <button
                            onClick={() => {
                                setShowModal(true);
                            }}
                            className="font-bold text-[0.93rem] py-0 px-6 text-white bg-gradient-to-r from-[#FF0099] to-[#8000E4] rounded-full flex items-center"
                        >
                            <span>Connect Wallet</span>
                        </button>
                    </div>
                </div>
            </div>
            <div
                className={
                    "fixed w-full h-screen z-50 top-0 left-0 bg-black bg-opacity-30 justify-center items-center " +
                    (showModal ? "flex" : "hidden")
                }
            >
                <div className="w-80 h-44 bg-white rounded-3xl relative flex justify-center items-center">
                    <button
                        onClick={() => {
                            setShowModal(false);
                        }}
                        className="absolute top-3 right-3 font-black"
                    >
                        X
                    </button>
                    <span>This only demo feature!</span>
                </div>
            </div>
        </>
    );
}

export default Header;
